import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const footerLinks = (
    <ul>
      <li>
        <Link to="/privacy">Privacy Policy</Link>
      </li>
      <li>
        <Link to="/contact">Contact Us</Link>
      </li>
      <li>
        <Link to="/splash">Learn More About PBX</Link>
      </li>

      <li></li>
    </ul>
  );

  return (
    <footer className="footer bg-dark">
      <h1>
        {/* <Link to='/'>
          <span>KiT</span> by Kinetx co
        </Link> */}
      </h1>
      <Fragment>{footerLinks}</Fragment>
      <div className="footer_rights">
        <span>Copyright ©2020 KIT by Kinetx Co LLC. All rights reserved</span>
        <span className="footer-divider">|</span>
        <span>110 Wall St. New York NY United States 10005</span>
      </div>
    </footer>
  );
};

export default Footer;
