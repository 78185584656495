import React, { Fragment, useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button2: {
    marginRight: theme.spacing(1),
    backgroundColor: "tomato",
    flexBasis: "25%",
    color: "#ffffff",
    marginTop: "1rem",
    marginBottom: "1rem",
    "&:hover": {
      backgroundColor: "var(--newColor)",
    },
  },
}));

const Result = ({ setForm, formData }) => {
  const classes = useStyles();

  const { extensions, minutes, name, email, company, minutePrice } = formData;

  const price = () => {
    return numberWithCommas(
      extensions === 1
        ? (minutes * minutePrice + 20).toFixed(2)
        : extensions === 2
        ? (minutes * minutePrice + 40).toFixed(2)
        : (minutes * minutePrice + 80).toFixed(2)
    );
  };

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const msg = `${price()}`;
  const [mail, setMail] = useState({
    recipient: email,
    estimate: msg,
  });

  const realExtensions =
    extensions == 20000 ? "0-40" : extensions == 40000 ? "40-100" : "100+";

  const [loading, setLoading] = useState(true);

  // developement

  const sendEmail = () => {
    fetch(
      `/sendEmail.php?mail=${mail.recipient}&name=${name}&hotel=${company}&price=${mail.estimate}&extensions=${realExtensions}&minutes=${minutes}`
    ).catch(err => console.error(err));
    fetch(
      `/sendEmail2.php?recipient=${mail.recipient}&name=${name}&price=${mail.estimate}`
    ).catch(err => console.error(err));
  };

  useEffect(() => {
    sendEmail();
    const timer = setTimeout(() => {
      setLoading(!loading);
    }, 5000);
  }, []);

  return (
    <Fragment>
      <div className="step step_result">
        {loading ? (
          <Loader />
        ) : (
          <div className="result_bill flex column_center">
            <h3>Hi, {`${name}`}</h3>
            <h1>
              Your new monthly bill is estimated to be <span>${price()}</span>.
            </h1>
            <p>
              An Email was sent to <strong>{`${email}`}</strong> with the above
              quote.
              <br />
              If you can't see an Email check your spam or{" "}
              <button onClick={sendEmail}>Click Here</button> to resend it.
            </p>
            <Button href="./" className={classes.button2}>
              Keep exploring kit
            </Button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Result;
