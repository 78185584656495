import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import * as rdd from "react-device-detect";

import "./CompareKit.css";

const useStyles = makeStyles({
  table: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  text: {
    color: "var(--mainColor)",
    width: "30%",
  },
  textMiddle: {
    color: "var(--mainColor)",
    width: "40%",
  },
  button: {
    backgroundColor: "var(--mainColor)",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "var(--newColor)",
    },
  },
});

function createData(name, competitors, kit) {
  return { name, competitors, kit };
}

const rows = [
  createData("Unlimited Users", "$$$", "No Extra Cost"),
  createData("Call Encryption", "$$$", "No Extra Cost"),
  createData("Online Fax", "$$$", "No Extra Cost"),
  createData("HD Voice", "$$$", "No Extra Cost"),
  createData("Group Call", "$$$", "No Extra Cost"),
  createData("Desktop & Mobile Apps", "$$$", "No Extra Cost"),
  createData("Automatic Call Recording", "$$$", "No Extra Cost"),
  createData("Voicemail to Text", "$$$", "No Extra Cost"),
  createData("IVR", "$$$", "No Extra Cost"),
];

const CompareKit = () => {
  const classes = useStyles();

  return (
    <div className="info-compare-container">
      <div className="info-compare">
        <h2>Compare KIT</h2>
        <p>Compare KIT with other business telephone services</p>
        <TableContainer>
          <Table className={classes.table}>
            {/* <Table className="info-table"> */}
            <TableHead>
              {!rdd.isMobileOnly ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell
                    className={classes.text}
                    align="center"
                    style={{ fontWeight: 400, fontSize: "1.5rem" }}
                  >
                    Our Competitors
                  </TableCell>
                  <TableCell
                    className={classes.text}
                    align="right"
                    style={{
                      fontWeight: 600,
                      fontSize: "1.5rem",
                      transform: "translateX(-25px)",
                    }}
                  >
                    KIT
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell
                    className={classes.text}
                    align="center"
                    style={{ fontWeight: 400, fontSize: "1rem" }}
                  >
                    Our Competitors
                  </TableCell>
                  <TableCell
                    className={classes.text}
                    align="right"
                    style={{
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    KIT
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.text}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.textMiddle} align="center">
                    {row.competitors}
                  </TableCell>
                  <TableCell
                    className={classes.text}
                    align="right"
                    style={{ fontWeight: 600 }}
                  >
                    {row.kit}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CompareKit;
