import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import "./Steps.css";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "35ch",
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const StepFour = ({ setForm, formData }) => {
  const { name, email, company } = formData;
  const classes = useStyles();
  return (
    <Fragment>
      <div className="step step_four flex column_center">
        <h2>Please fill in your info to discover your future savings</h2>
        <form className={classes.root} noValidate autoComplete="on">
          <TextField
            id="name"
            name="name"
            label="Name"
            value={name}
            variant="outlined"
            onChange={setForm}
            required
          />
          <TextField
            id="email"
            name="email"
            label="Email"
            value={email}
            variant="outlined"
            onChange={setForm}
            required
          />
          <TextField
            id="company"
            name="company"
            label="Hotel"
            value={company}
            variant="outlined"
            onChange={setForm}
          />
        </form>
      </div>
    </Fragment>
  );
};

export default StepFour;
