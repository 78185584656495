import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import "./App.css";
import Calculator from "./components/Calculator/Calculator";
import Form from "./components/Form/Form";
import Homepage from "./components/Homepage/Homepage";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Features from "./components/Features/Features";
import Legal from "./components/Solutions/Legal";
import Retail from "./components/Solutions/Retail";
import Splash from "./components/Splash/Splash";
import Privacy from "./components/Privacy/Privacy";
import Corporate from "./components/Solutions/Corporate";
import Hospitality from "./components/Solutions/Hospitality";
import Unsubscribe from "./components/Splash/Unsubscribe";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
// import ReactGA from "react-ga";

// ReactGA.initialize("UA-137362770", {
//   debug: true,
//   gaOptions: { cookieDomain: "none" },
// });
// ReactGA.pageview(window.location.pathname + window.location.search);

const kitTheme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(3, 40, 54)",
    },
    secondary: {
      main: "rgb(255, 99, 71)",
    },
  },
});

const history = createBrowserHistory();

// Initialize google analytics page view tracking
// history.listen(location => {
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

function App() {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  // }, []);
  return (
    <Router history={history}>
      <Fragment>
        <ThemeProvider theme={kitTheme}>
          <Navbar />
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/calculator" component={Calculator} />
              <Route exact path="/about" component={About} />
              <Route exact path="/form" component={Form} />
              <Route exact path="/features" component={Features} />
              {/* <Route exact path='/solutions' component={Solutions} /> */}
              {/* <Route exact path="/legal" component={Legal} />
              <Route exact path="/retail" component={Retail} />
              <Route exact path="/corporate" component={Corporate} />
              <Route exact path="/hospitality" component={Hospitality} /> */}
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/splash" component={Splash} />
              {/* <Route exact path="/unsubscribe" component={Unsubscribe} /> */}
              <Route exact path="/privacy" component={Privacy} />
            </Switch>
          </ScrollToTop>
          <Footer />
        </ThemeProvider>
      </Fragment>
    </Router>
  );
}

export default App;
