import React, { Fragment, useEffect } from "react";
import Learn from "../Learn/Learn";
import Recommendations from "../Recommendations/Recommendations";

import "./About.css";

const About = () => {
  useEffect(() => {
    document.title = "KIT by Kinetx Co | About";
  }, []);

  return (
    <Fragment>
      <div className="about_hero">
        <div className="darkblue_overlay">
          <h1>We are proud of our service to the hotel industry</h1>
          {/* <p className='about_hero_subtitle'>Proudly Served by Kinetx Co</p> */}
        </div>
      </div>
      <div className="about_main">
        <div className="about_main_top">
          {/* <h1>Our KIT</h1> */}
          <p>
            Unlike other providers, we specialize in the hotel industry,
            focusing on your specific needs and ideal solutions. We partner with
            our clients to implement efficient, reliable systems. The system
            will be tailored to your needs, with a pricing structure aimed to
            create a return in investment within the first year. We provide the
            latest technology at the right price, without binding contracts or
            expensive fees.
          </p>
          <p>
            Switching to KIT is fast and seamless. Before switching over, we
            test your system and then come on-site to change over each line to
            ensure no service loss.
          </p>
          <p>Sign up today, and stop paying for unused room lines!</p>
        </div>
        <div className="divider"></div>
        {/* <div className="about_main_middle">
          <div className="about_middle_text">
            <h2>
              "The telephone is the greatest nuisance among conveniences, the
              greatest convenience among nuisances."
            </h2>

            <p>- Robert Staughton Lynd</p>
          </div>
          <div className="about_middle_img">
            <div className="blue_overlay"></div>
          </div>
        </div>
        <div className="about_main_bottom">
          <div className="about_bottom_text">
            <p>
              We provide the latest technology at the right price without
              binding contracts or expensive fees. Keep your business moving
              forward and feel the freedom with KIT.
            </p>
          </div>
          <div className="about_bottom_img">
            <div className="darkblue_overlay">
              <h1>What Sets Us Apart</h1>
            </div>
          </div>
        </div> */}
        <Learn />
        {/* <div className="divider"></div>
        <h1>WHAT OUR CLIENTS SAY</h1>
        <Recommendations /> */}
      </div>
    </Fragment>
  );
};

export default About;
