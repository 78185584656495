import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hooks-helper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import Result from "./Result";

import "./Calculator.css";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(14, 38, 53) 0%,rgb(7, 81, 108) 50%,rgb(147, 203, 226) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "var(--newColor)",
    },
  },
  line: {
    height: 5,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2rem",
  },
  active: {
    backgroundColor: "var(--secColor)",
  },
  completed: {
    backgroundColor: "var(--newColor)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "tomato",
    flexBasis: "25%",
  },
  button2: {
    marginRight: theme.spacing(1),
    backgroundColor: "tomato",
    flexBasis: "25%",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "var(--newColor)",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const defaultData = {
  extensions: "",
  minutes: "",
  name: "",
  email: "",
  company: "",
  minutePrice: "0.00696",
};

const Calculator = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setForm] = useForm(defaultData);

  const steps = getSteps();

  const props = { formData, setForm, defaultData };

  function getSteps() {
    return ["", "", "", ""];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <StepOne {...props} />;
      case 1:
        return <StepTwo {...props} />;
      case 2:
        return <StepThree {...props} />;
      case 3:
        return <StepFour {...props} />;
      default:
        return "Unknown step";
    }
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    document.title = "KIT by Kinetx Co | Calculator";
  }, []);

  return (
    <Fragment>
      <div className="calculator_hero">
        <div className="darkblue_overlay">
          <h1>Estimate your future bill</h1>
        </div>
      </div>
      <div
        className={
          activeStep === steps.length
            ? "calculator_last flex column_center"
            : "calculator_main flex column_center"
        }
      >
        <div className={classes.root}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div className="calculator_result flex column_center">
                <Typography className={classes.instructions}>
                  <Result {...props} />
                </Typography>
                {/* <Button href='./' className={classes.button2}>
                  Keep exploring kit
                </Button> */}
              </div>
            ) : (
              <div className="calculator_step_content flex column_center">
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div className="calculator_btns flex row_center">
                  {activeStep === 0 ? (
                    ""
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    disabled={
                      (activeStep === 0 && formData.extensions < 10000) ||
                      (activeStep === 1 && formData.minutes === "") ||
                      (activeStep === 3 &&
                        (formData.name === "" || formData.email === ""))
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Calculator;
