import React, { Fragment, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

import "./Splash.css";

const Splash = () => {
  const [data, setData] = useState({
    email: "",
    message: "is interested about free fact sheet",
    sent: false,
    buttonText: "Submit",
    err: "",
  });

  const { register, errors, handleSubmit, watch } = useForm({});

  const handleChange = e => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const sendEmail = () => {
    setData({
      ...data,
      buttonText: "Sending...",
    });
    // console.log(data);
    fetch(
      `/pbxMail.php?&sender=${data.email}&text=${data.message}`
    ).catch(err => console.error(err));
    fetch(`/pbxMail2.php?&sender=${data.email}`).catch(err =>
      console.error(err)
    );
    setData({
      ...data,
      buttonText: "Check your inbox!",
    });
  };

  useEffect(() => {
    document.title = "KIT by Kinetx Co | Information Sheet";
  }, []);
  return (
    <Fragment>
      <div className="splash">
        <div className="splash-card">
          <div className="splash-card-overlay">
            <div className="splash-card-text">
              <h1>GET YOUR FREE INFORMATION&nbsp;SHEET</h1>
              <p className="welcome-text">
                Learn how KIT reduces phone bills by up to 91%
              </p>
              <form
                noValidate
                autoComplete="on"
                onSubmit={e => e.preventDefault()}
              >
                {/* <TextField
                  className="form_field"
                  fullWidth
                  margin="dense"
                  type="email"
                  name="email"
                  id="email"
                  label="Enter your email address"
                  variant="outlined"
                  value={data.email}
                  onChange={handleChange}
                  inputRef={register({
                    required: "Email is requierd",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                /> */}
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  onChange={handleChange}
                  ref={register({
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <p className="form_error">
                    {"⚠ "}
                    {errors.email.message}
                  </p>
                )}
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "tomato",
                    color: "#ffffff",
                    marginTop: "1rem",
                    boxShadow: "none",
                  }}
                  type="submit"
                  onClick={handleSubmit(sendEmail)}
                >
                  {data.buttonText}
                </Button>
              </form>
            </div>
            {/* <img src={require("../../Images/Splash.jpg")} /> */}
          </div>
          {/* <Button
              variant="contained"
              style={{
                color: "#ffffff",
                backgroundColor: "tomato",
              }}
              href="./splash"
            >
              DOWNLOAD YOUR FREE FACT SHEET
            </Button> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Splash;
