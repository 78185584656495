import React, { Fragment } from "react";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LazyLoad from "react-lazyload";
import * as rdd from "react-device-detect";

import "./Info.css";

const useStyles = makeStyles({
  // table: {
  //   width: "100%",
  //   backgroundColor: "#ffffff",
  // },
  // text: {
  //   color: "var(--mainColor)",
  // },
  button: {
    backgroundColor: "var(--mainColor)",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "var(--mainColor)",
    },
  },
});

function createData(name, competitors, kit) {
  return { name, competitors, kit };
}

const rows = [
  createData("Unlimited Users", "$$$", "Included. No Extra Cost"),
  createData("Call Encryption", "$$$", "Included. No Extra Cost"),
  createData("Online Fax", "$$$", "Included. No Extra Cost"),
  createData("HD voice", "$$$", "Included. No Extra Cost"),
  createData("Call Group", "$$$", "Included. No Extra Cost"),
  createData("Desktop & Mobile Apps", "$$$", "Included. No Extra Cost"),
  createData("Automatic Call Recording", "$$$", "Included. No Extra Cost"),
  createData("Voicemail to Text", "$$$", "Included. No Extra Cost"),
  createData("IVR", "$$$", "Included. No Extra Cost"),
];

const Info = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className="info-container">
        <h2>Why KIT?</h2>
        {/* <LazyLoad height="100%">
          <div className="info-video">
            <ReactPlayer
              url="https://www.youtube.com/watch?time_continue=13&v=fIYOGLsrIxc&feature=emb_logo"
              playing
              loop
              controls
              volume="0"
              muted
              width="80vw"
              height="100%"
            />
          </div>
        </LazyLoad> */}
        <div className="infos">
          <div className="info-card card1">
            <h2>Cut Costs</h2>
            <p>
              <b>Reduce</b> your telephone bill by up to 91% through our
              pay-per-use system. <b>Take control</b> of your bill by only
              paying for actual call usage.
            </p>
            <Button className={classes.button} href="./Calculator">
              Cost Calculator
            </Button>
          </div>

          <div className="info-card card2">
            <h2>Enhanced Customer Experience</h2>
            <p>
              Our system provides <b>over 100 features</b> such as caller ID
              lookups, email notifications, and fully customized IVR. All
              features are included at <b>no extra cost</b> to keep your
              business moving forward.
            </p>
            <Button className={classes.button} href="./Features">
              See All Features
            </Button>
          </div>
        </div>
        <div className="divider"></div>
        <div className="info-how">
          <h2>KIT Advantages</h2>
          <div className="info-how-cards">
            <div className="how-card shadow-bottom-left">
              <h3>Hosted PBX</h3>
              <p>
                Kit is hosted through our cloud-based VoIP platform for quick
                and easy setup with no hardware costs. Unlike others, your KIT
                PBX telephone system will only service your hotel.
              </p>
            </div>
            <div className="how-card shadow-bottom-left">
              <h3>Usage Based Pricing</h3>
              <p>
                Stop paying for unused phone lines and only pay for actual
                usage, reducing up to 91% of your current telephone bill.
              </p>
            </div>
            <div className="how-card shadow-bottom-left">
              <h3>An Entire KIT</h3>
              <p>
                Create a personalized solution for your hotel by selecting the
                features you want and need. Customizing your KIT comes at no
                extra cost.
              </p>
            </div>
          </div>
        </div>
        <div className="divider"></div>

        <div className="info-customize">
          <h2>Your Business KIT</h2>
          <ul className="customize-list">
            <li className="customize-item">
              <div className="info_check">
                <CheckCircleIcon />
              </div>{" "}
              Voicemail to Email
            </li>
            <li className="customize-item">
              <div className="info_check">
                <CheckCircleIcon />
              </div>{" "}
              Line Encryption
            </li>
            <li className="customize-item">
              <div className="info_check">
                <CheckCircleIcon />
              </div>{" "}
              CRM Integrations
            </li>
            <li className="customize-item">
              <div className="info_check">
                <CheckCircleIcon />
              </div>{" "}
              Remote Users
            </li>
            <li className="customize-item">
              <div className="info_check">
                <CheckCircleIcon />
              </div>{" "}
              Call Recording
            </li>
            <li className="customize-item">
              <div className="orange_check">
                <CheckCircleIcon />
              </div>{" "}
              And more...
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Info;
