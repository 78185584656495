import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";

import "./Navbar.css";

const useStyles = makeStyles({
  list: {
    width: "50vw",
    color: "var(--newColor)",
    backgroundColor: "#ffffff",
    Height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: "3rem",
    position: "relative",
  },
  button: {
    color: "#f8f8f8",
  },
  item: {
    marginTop: "1rem",
  },
  close: {
    position: "absolute",
    top: "10px",
    right: "18px",
    fontSize: "2rem",
    color: "var(--newColor)",
  },
});

const Navbar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    right: false,
  });

  const subMenu = () => {
    setOpen(!open);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <div className={classes.list} role="presentation">
      <span
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        className={classes.close}
      >
        <i class="fas fa-times"></i>
      </span>
      <List>
        <ul className="burger_list">
          <li>
            <NavLink
              to="./about"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              About KIT
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="./features"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              Features
            </NavLink>
          </li>
          {/* <li class='dropdown'>
            <a href='#' className='dropbtn' onClick={subMenu}>
              Industries
            </a>
          </li>
          <li className={open ? 'sub_menu_open' : 'sub_menu_close'}>
            <Link
              to='./legal'
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              Legal
            </Link>
            <Link
              to='./retail'
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              Retail
            </Link>
            <Link
              to='./corporate'
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              Corporate
            </Link>
            <Link
              to='./hospitality'
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              Hospitality
            </Link>
          </li> */}
          <li>
            <NavLink
              to="/contact"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              Contact Us
            </NavLink>
          </li>
          <li>
            <Link
              to="/calculator"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "tomato",
                  color: "#ffffff",
                  boxShadow: "none",
                }}
              >
                Get a Quote
              </Button>
            </Link>
          </li>
        </ul>
      </List>
    </div>
  );

  const links = (
    <ul>
      <li>
        <NavLink
          to="./about"
          activeStyle={{
            // borderBottom: "2px solid tomato",
            boxShadow: "0 -2px 0 0 tomato inset",
            padding: "calc(2rem - 2px) 0",
          }}
        >
          About KIT
        </NavLink>
      </li>
      <li>
        <NavLink
          to="./features"
          activeStyle={{
            // borderBottom: "2px solid tomato",
            boxShadow: "0 -2px 0 0 tomato inset",
            padding: "calc(2rem - 2px) 0",
          }}
        >
          Features
        </NavLink>
      </li>
      {/* <li className='dropdown'>
        <NavLink to='#' className='dropbtn' activeClassName='activeLink'>
          Industries
        </NavLink>
        <div className='dropdown-content'>
          <Link to='./legal'>Legal </Link>
          <Link to='./retail'>Retail</Link>
          <Link to='./corporate'>Corporate</Link>
          <Link to='./hospitality'>Hospitality</Link>
        </div>
      </li> */}
      <li>
        <NavLink
          to="/contact"
          activeStyle={{
            // borderBottom: "2px solid tomato",
            boxShadow: "0 -2px 0 0 tomato inset",
            padding: "calc(2rem - 2px) 0",
          }}
        >
          Contact Us
        </NavLink>
      </li>
      <li>
        <Link to="/calculator">
          <Button
            variant="contained"
            style={{ backgroundColor: "tomato", color: "#ffffff" }}
          >
            Get a Quote
          </Button>
        </Link>
        {/* <Link to="/calculator">
          <Button
            variant="contained"
            style={{ backgroundColor: "tomato", color: "#ffffff" }}
          >
            Get a Quote
          </Button>
        </Link> */}
      </li>
    </ul>
  );

  const burger = (
    <div className="hamburger">
      {["right"].map(anchor => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className={classes.button}
          >
            <i className="fas fa-2x fa-bars"></i>
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className="navbar bg-dark">
      <nav className="navbar navbar-container">
        <div className="navbar-logo">
          <Link to="/">
            <img src={require("../../Images/KITlogoF.png")} />
            <span>KIT</span> by Kinetx Co
          </Link>
        </div>
        <Fragment>
          {links}
          {burger}
        </Fragment>
      </nav>
    </div>
  );
};

export default Navbar;
