import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

import "./Steps.css";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "35ch",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StepTwo = ({ setForm, formData }) => {
  const { extensions, minutes } = formData;
  const classes = useStyles();

  return (
    <Fragment>
      <div className="step step_two flex column_center">
        <h2>Estimated monthly call traffic (minutes/month)</h2>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            type="number"
            id="minutes"
            name="minutes"
            variant="outlined"
            labelId="minMo label"
            placeholder={extensions}
            value={minutes}
            onChange={setForm}
            label="Minutes/Month"
          />
        </FormControl>
        <p className="pad12">
          *Estimated call traffic is provided based on previous data analytics
          and may vary based on usage.
        </p>
        <p>
          Simple usage-based pricing means you don’t get locked into big
          contracts. Pay for what you actually use.
        </p>
      </div>
    </Fragment>
  );
};

export default StepTwo;
