import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import "./Steps.css";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "35ch",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StepOne = ({ setForm, formData }) => {
  //   const [formData, setForm] = useForm(defaultData);

  const { extensions } = formData;
  const classes = useStyles();

  const required = value => (value ? undefined : "Required");

  return (
    <Fragment>
      <div className="step step_one flex column_center">
        <h2>How many extensions (lines) do you need?</h2>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            Number of Extensions
          </InputLabel>
          <Select
            fullWidth
            id="extensions"
            name="extensions"
            value={extensions}
            onChange={setForm}
            label="Number of Extensions"
            validate={required}
          >
            <MenuItem value={20000}>0 to 40 Lines</MenuItem>
            <MenuItem value={40000}>40 to 100 Lines</MenuItem>
            <MenuItem value={100000}>100+ Lines</MenuItem>
          </Select>
        </FormControl>
        <p className="pad12">
          Simple usage-based pricing means you don’t get locked into big
          contracts. Pay for what you actually use.
        </p>
        <p className="pad12">
          Please fill out your info to discover your future savings
        </p>
        {/* <p className="pad12">
          Extensions allow access to your KIT phone system.
        </p> */}
      </div>
    </Fragment>
  );
};

export default StepOne;
