import React, { Fragment, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import axios from "axios";

import { Button } from "@material-ui/core";

import "./Contact.css";

const useStyles = makeStyles(theme => ({
  root_sm: {
    margin: theme.spacing(1),
    width: "35ch",
  },
  root_lg: {
    margin: theme.spacing(1),
    width: "72ch",
  },
  breakpoints: {
    values: {
      mobile: 320,
      tablet: 481,
      smScreen: 769,
      laptop: 1025,
      desktop: 1201,
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldSm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  formError: {
    fontSize: "14px",
    color: "red",
    paddingLeft: "1rem",
    "&::before": {
      content: "⚠ ",
    },
  },
}));

const Contact = () => {
  // const [formData, setForm] = useForm(defaultData);
  const classes = useStyles();

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phone: "",
    sent: false,
    buttonText: "Submit",
    err: "",
  });

  const { register, errors, handleSubmit, watch } = useForm({});

  const handleChange = e => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const sendEmail = () => {
    setData({
      ...data,
      buttonText: "Sending...",
    });
    console.log(data);
    fetch(
      `/newMail.php?&sender=${data.email}&text=${data.message}&phone=${data.phone}&name=${data.firstName} ${data.lastName}`
    ).catch(err => console.error(err));
    fetch(
      `/newMail2.php?&sender=${data.email}&name=${data.firstName} ${data.lastName}`
    ).catch(err => console.error(err));
    setData({
      ...data,
      buttonText: "Resend Email",
    });
  };

  useEffect(() => {
    document.title = "KIT by Kinetx Co | Contact";
  }, []);

  return (
    <Fragment>
      <div className="contact_hero">
        <div className="darkblue_overlay">
          <h1>Contact us</h1>
        </div>
      </div>
      <div className="contact_details">
        <div className="contact_contacts">
          <h4>(877)7-GETKIT</h4>
          <h4>contact@getkit.us</h4>
        </div>
        <p>Have any questions? Our team is happy to help.</p>
      </div>
      <div className="contact_form">
        <form noValidate autoComplete="on" onSubmit={e => e.preventDefault()}>
          <div className="pad">
            <TextField
              className="form_field"
              // className={classes.root_sm}
              name="firstName"
              id="firstName"
              label="First Name"
              variant="outlined"
              value={data.firstName}
              onChange={handleChange}
              required
              inputRef={register({
                required: "First name is required",
              })}
            />
            <TextField
              className="form_field"
              // className={classes.root_sm}
              name="lastName"
              id="lastName"
              label="Last Name"
              variant="outlined"
              value={data.lastName}
              onChange={handleChange}
              // required
              // inputRef={register({
              //   required: "Last name is required",
              // })}
            />
          </div>

          <div className={classes.container}>
            <div className={classes.textFieldSm}>
              {errors.firstName && (
                <p className={classes.formError}>
                  {"⚠ "}
                  {errors.firstName.message}
                </p>
              )}
            </div>
            <div className={classes.textFieldSm}>
              {errors.lastName && (
                <p className={classes.formError}>
                  {"⚠ "}
                  {errors.lastName.message}
                </p>
              )}
            </div>
          </div>
          <div className="pad">
            <TextField
              className="form_field"
              // className={classes.root_sm}
              name="email"
              id="email"
              label="Email"
              variant="outlined"
              value={data.email}
              onChange={handleChange}
              required
              inputRef={register({
                required: "Email is requierd",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            />

            <TextField
              className="form_field"
              // className={classes.root_sm}
              name="phone"
              id="phone"
              label="Phone"
              variant="outlined"
              value={data.phone}
              onChange={handleChange}
            />
          </div>
          <div className={classes.container}>
            <div className={classes.textFieldSm}>
              {errors.email && (
                <p className={classes.formError}>
                  {"⚠ "}
                  {errors.email.message}
                </p>
              )}
            </div>
          </div>
          <div className="contact_textarea">
            <TextField
              fullWidth
              className="contact_message"
              name="message"
              id="message"
              label="Message"
              multiline
              rows={5}
              variant="outlined"
              value={data.message}
              onChange={handleChange}
              width={1}
              required
              inputRef={register({
                required: "Message is required",
              })}
            />
            {errors.message && (
              <p className={classes.formError}>
                {"⚠ "}
                {errors.message.message}
              </p>
            )}
          </div>
          <div className="contact_form_btn">
            <Button
              variant="variant=ed"
              style={{
                backgroundColor: "tomato",
                color: "#ffffff",
                width: "100%",
                padding: "0.5rem",
              }}
              // onClick={sendEmail}
              onClick={handleSubmit(sendEmail)}
              type="submit"
            >
              {data.buttonText}
              {/* Submit */}
            </Button>
          </div>
        </form>
      </div>
      <div className="contact_map"></div>
    </Fragment>
  );
};

export default Contact;
