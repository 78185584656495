import React, { Fragment } from "react";

import "./Loader.css";

const Loader = () => {
  return (
    <Fragment>
      <p className="loader-p">
        Your&nbsp;old <br />
        Phone&nbsp;bill
      </p>
      <div className="cont">
        <div className="paper"></div>
        <button className="shredder">
          <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          KIT System
        </button>
        <div className="g-cont">
          <div className="garbage"></div>
          <div className="garbage"></div>
          <div className="garbage"></div>
          <div className="garbage"></div>
          <div className="garbage"></div>
          <div className="garbage"></div>
          <div className="garbage"></div>
          <div className="garbage"></div>
        </div>
      </div>
    </Fragment>
  );
};

export default Loader;
