import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

const Learn = () => {
  const [data, setData] = useState({
    email: "",
    message: "is interested about free information sheet",

    sent: false,
    buttonText: "Tell Me More",
    err: "",
  });

  const { register, errors, handleSubmit, watch } = useForm({});

  const handleChange = e => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  // const sendEmail = () => {
  //   setData({
  //     ...data,
  //     buttonText: "Sending...",
  //   });
  //   console.log(data);
  //   fetch(
  //     `/pbxMail.php?&sender=${data.email}&text=${data.message}`
  //   ).catch(err => console.error(err));
  //   setData({
  //     ...data,
  //     buttonText: "Email Sent!",
  //   });
  // };

  const sendEmail = () => {
    setData({
      ...data,
      buttonText: "Sending...",
    });
    // console.log(data);
    fetch(
      `/pbxMail.php?&sender=${data.email}&text=${data.message}`
    ).catch(err => console.error(err));
    fetch(`/pbxMail2.php?&sender=${data.email}`).catch(err =>
      console.error(err)
    );
    setData({
      ...data,
      buttonText: "Resend Email",
    });
  };

  return (
    <div className="learn-more">
      <div className="learn-more-form">
        <h3>Enter your email address to learn more</h3>
        <form noValidate autoComplete="on" onSubmit={e => e.preventDefault()}>
          <input
            type="email"
            name="email"
            placeholder="Enter your email address"
            onChange={handleChange}
            ref={register({
              required: "Email is requierd",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
          />
          {/* <TextField
            className="form_field"
            type="email"
            name="email"
            id="email"
            label="Enter your email address"
            variant="outlined"
            value={data.email}
            onChange={handleChange}
            inputRef={register({
              required: "Email is requierd",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
          /> */}
          {errors.email && (
            <p className="form_error_learn">
              {"⚠ "}
              {errors.email.message}
            </p>
          )}
          <Button
            variant="contained"
            style={{
              backgroundColor: "tomato",
              color: "#ffffff",
              marginTop: "1rem",
              boxShadow: "none",
            }}
            type="submit"
            onClick={handleSubmit(sendEmail)}
          >
            {data.buttonText}
          </Button>
        </form>
      </div>
      <div className="learn-more-text">
        <p>
          Recieve your information sheet comparing hosted PBX solutions to other
          solutions available, empowering you to make the best choice for your
          hotel.
        </p>
      </div>
    </div>
  );
};

export default Learn;
