import React, { Fragment, useEffect } from "react";
import Landing from "../Landing/Landing";
import Info from "../Info/Info";
import Recommendations from "../Recommendations/Recommendations";

const Homepage = () => {
  useEffect(() => {
    document.title = "KIT by Kinetx Co";
  }, []);

  return (
    <Fragment>
      <Landing />
      {/* <Recommendations /> */}
      <Info />
    </Fragment>
  );
};

export default Homepage;
