import React, { Fragment } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button } from '@material-ui/core';
import { IntegrationsModal, NextModal } from '../Modals/Modals';

import './Retail.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  h2: {
    fontWeight: 400,
    fontSize: '2.5rem',
    padding: '1rem',
  },
  paper: {
    color: 'var(--mainColor)',
    backgroundColor: '#ffffff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: 'var(--mainColor)',
    width: 'auto',
    padding: '0.5rem 1rem',
    color: '#ffffff',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: 'var(--newColor)',
    },
  },
}));

const Retail = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [intOpen, setIntOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleIntOpen = () => {
    setIntOpen(true);
  };

  const handleIntClose = () => {
    setIntOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <div className='retail_hero'>
        <div className='blue_overlay'>
          <h1>Retail Communication Solutions</h1>
          {/* <p>
            Introduce efficiency and reliability to your business communications
            system
          </p> */}
        </div>
      </div>
      <div className='retail_main flex column_center'>
        <div className='retail_card flex row_center shadow'>
          <div className='retail_card_img'></div>
          <div className='retail_card_text flex column_center pad12 shadow_bottom_right'>
            <h2>Create an enjoyable customer experience</h2>
            <p className='pad12'>
              Improve customer experience through innovative features
            </p>
            <div className='retail_checks flex'>
              <div className='retail_check_box flex row_center '>
                <div className='retail_check'>
                  <CheckCircleIcon />
                </div>
                <span>Call Recording</span>
              </div>
              <div className='retail_check_box flex row_center'>
                <div className='retail_check'>
                  <CheckCircleIcon />
                </div>
                <span>Music On Hold</span>
              </div>
              <div className='retail_check_box flex row_center'>
                <div className='retail_check'>
                  <CheckCircleIcon />
                </div>
                <span>Call Queue</span>
              </div>
              <div className='retail_check_box flex row_center'>
                <div className='retail_check'>
                  <CheckCircleIcon />
                </div>
                <span>CRM Integrations</span>
              </div>
            </div>
            <Button className={classes.button} onClick={handleOpen}>
              See All Features
            </Button>
          </div>
          <Modal
            aria-labelledby='features-modal'
            aria-describedby='features-modal-description'
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <h1 className={classes.h2}>Our Most Popular Features</h1>
                <div className='features_modal flex row_center'>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Call Announce</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Caller ID</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Call Screening</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Call Waiting</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Do Not Disturb</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Caller Name Lookup</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Voicemail to Email</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Missed Call to Email</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Caller Queue</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Call Recording</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Music on Hold</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Click-To-Call Me</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Call Continuity</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Call Hold</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Call Logs</span>
                  </div>
                  <div className='features_modal_item flex row_center'>
                    <CheckCircleIcon fontSize='medium' />
                    <span>Call Park</span>
                  </div>
                </div>
                <p>
                  Missing a feature? Contact us and talk to one of our team
                  members to discuss your business's needs
                </p>
              </div>
            </Fade>
          </Modal>
        </div>
        <div className='divider'></div>
        <div className='retail_integrations_modal'>
          <IntegrationsModal />
        </div>
        <div className='divider'></div>
        <div className='retail_bottom'>
          <div className='retail_bottom_card flex row_center shadow'>
            <div className='retail_bottom_text flex column_center pad12'>
              <h1>
                A dedicated KIT for <span>your</span> business!
              </h1>
              <p>
                On big business days, you can't afford for anything to go wrong.
                KIT puts your business first by providing a dedicated system,
                which means you will be up and running at all times. Build
                better a better business and get KIT!
              </p>
              <p>
                Our solution will allow you to be up and running at all times.
              </p>
            </div>
            <div className='retail_bottom_img shadow-bottom-right'></div>
          </div>
        </div>
        <div className='divider'></div>
        <NextModal />
      </div>
    </Fragment>
  );
};

export default Retail;
