import React, { Fragment, useEffect } from "react";
import CompareKit from "../Info/CompareKit";

import "./Features.css";

const Features = () => {
  useEffect(() => {
    document.title = "KIT by Kinetx Co | Features";
  }, []);

  return (
    <Fragment>
      <div className="features_hero">
        <div className="darkblue_overlay">
          <h1>All the features you need at no extra cost</h1>
        </div>
      </div>
      <div className="features_main">
        <div className="features_main_top">
          {/* <h1>The KIT Your Business Needs!</h1> */}
          {/* <p>
            KIT is fully customizable for your business needs. KIT is designed
            fit your business perfectly. Each KIT is uniqly tailored to acheive
            the best telephone service for your business.
          </p> */}
          <div className="features_main_features">
            <div className="feature">
              <h2>Freedom & Flexibility</h2>
              <p>
                Boost your hotel's productivity, flexibility, and efficiency by
                creating a custom solution for your hotel's needs. Experience
                the difference of an advanced communication platform built just
                for you!
              </p>
            </div>
            <div className="feature">
              <h2>No Additonal Fees</h2>
              <p>
                Completely customize your KIT at no extra cost. Whether you add
                1 or 10 features, your bill will not change. Maximize your
                hotel's potential with KIT's endless possibilities.
              </p>
            </div>
          </div>
        </div>

        <div className="features_main_middle">
          <div className="features_main_features">
            <div className="feature">
              <h2>Never Miss A Call Again</h2>
              <p>
                Receive voicemails transcribed into emails and audio files. We
                value your time by making voicemail efficient and readable.
              </p>
            </div>
            <div className="feature">
              <h2>Call Recording</h2>
              <p>
                Call recordings allow your business to go back in time and
                listen to the interaction as they happened, offering an
                opportunity to find missed information.
              </p>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <CompareKit />
        {/* <div className="feature_video">
          <video
            width="100%"
            height="100%"
            controls
            autoPlay
            muted
            src={require("../../Images/Draft2.mov")}
          ></video>
        </div> */}
        <div className="divider"></div>
        <div className="features_list">
          <h2>Integral Features</h2>
          <ul>
            <li className="features_list_item">Call Continuity</li>
            <li className="features_list_item">Call Hold</li>
            <li className="features_list_item">Call Logs</li>
            <li className="features_list_item">Call Park</li>
            <li className="features_list_item">Call Queue</li>
            <li className="features_list_item">Call Recording</li>
            <li className="features_list_item">Music On Hold</li>
            <li className="features_list_item">Click-To-Call Me</li>
            <li className="features_list_item">Do Not Disturb</li>
            <li className="features_list_item">Caller ID Lookup</li>
            <li className="features_list_item">Voicemail to Email</li>
            <li className="features_list_item">Missed Call to Email</li>
            <li className="features_list_item">Call Announce</li>
            <li className="features_list_item">Caller ID Block</li>
            <li className="features_list_item">Call Screening</li>
            <li className="features_list_item">Call Waiting</li>
          </ul>
        </div>
        <div className="features_main_bottom">
          <h2>Call Encryption</h2>
          <div className="features_bottom">
            <p>
              Session Initiated Protocol (SIP) combined with Transport Layer
              Security (TLS) encrypts all information passed between the client
              and the cloud. We ensure that only authorized users are permitted
              to connect to your KIT cloud system.
            </p>
            <p>
              Secure Real-Time Transport Protocol (SRTP) delivers encrypted
              Voice over Internet Protocol (VoIP). TLS is used with STRP on VoIP
              systems, ensuring end-to-end encryption keeping your hotel safe
              from any malicious activity.
            </p>
          </div>
        </div>
        {/* <CompareKit /> */}
        {/* <div className="divider"></div> */}
        <div className="features_main_bottom white">
          <h2>Next Level VoIP</h2>
          <p>Continue to customize your KIT with third-party applications!</p>
          <ul className="features_integrations">
            <li className="features_integrate_img">
              <img src={require("../../Images/salesforce.jpg")} alt="" />
            </li>
            <li className="features_integrate_img">
              <img src={require("../../Images/office365.jpg")} alt="" />
            </li>
            <li className="features_integrate_img">
              <img src={require("../../Images/slack.jpg")} alt="" />
            </li>
            <li className="features_integrate_img">
              <img src={require("../../Images/clio.jpg")} alt="" />
            </li>
            <li className="features_integrate_img">
              <img src={require("../../Images/Gsuite.png")} alt="" />
            </li>
            <li className="features_integrate_img">
              <img src={require("../../Images/zoho.jpg")} alt="" />
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Features;
