import React, { useState } from 'react';
import axios from 'axios';

import './Form.css';

const Form = () => {
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const submitRequest = async (e) => {
    e.preventDefault();
    console.log({ email, message, name, company, phone });

    const response = await fetch('/sendMail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ email, message, name, company, phone }),
    });
    console.log(response);

    const resData = await response.json();
    if (resData.status === 'success') {
      alert('Message Sent.');
      this.resetForm();
    } else if (resData.status === 'fail') {
      alert('Message failed to send.');
    }
  };

  //   const mail = () => {
  //     axios({
  //       method: 'POST',
  //       url: '/sendEmail',
  //       data: {
  //         name: name,
  //         email: email,
  //         messageHtml: message,
  //       },
  //     }).then((response) => {
  //       if (response.data.msg === 'success') {
  //         alert('Email sent, awesome!');
  //         this.resetForm();
  //       } else if (response.data.msg === 'fail') {
  //         alert('Oops, something went wrong. Try again');
  //       }
  //     });
  //   };

  return (
    <div className='form_container'>
      <h1 className='brand'>
        <span>Acme</span> Web Design
      </h1>
      <div className='wrapper animated bounceInLeft'>
        <div className='company-info'>
          <h3>Acme Web Design</h3>
          <ul>
            <li>
              <i className='fa fa-road'></i> 44 Something st
            </li>
            <li>
              <i className='fa fa-phone'></i> (555) 555-5555
            </li>
            <li>
              <i className='fa fa-envelope'></i> test@acme.test
            </li>
          </ul>
        </div>
        <div className='form_contact'>
          <h3>Email Us</h3>
          <form action='sendMail' onSubmit={submitRequest}>
            <p>
              <label>Name</label>
              <input
                type='text'
                name='name'
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </p>
            <p>
              <label>Company</label>
              <input
                type='text'
                name='company'
                onChange={(e) => setCompany(e.target.value)}
                value={company}
              />
            </p>
            <p>
              <label>Email Address</label>
              <input
                type='email'
                name='email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </p>
            <p>
              <label>Phone Number</label>
              <input
                type='text'
                name='phone'
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </p>
            <p className='full'>
              <label>Message</label>
              <textarea
                name='message'
                rows='5'
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              ></textarea>
            </p>
            <p className='full'>
              <button type='submit'>Submit</button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
