import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./Privacy.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#ededed",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
  details: {
    backgroundColor: "#f8f8f8",
  },
}));

const Privacy = () => {
  const classes = useStyles();

  return (
    <div className="privacy-container flex column_center">
      <h1>KIT's Privacy Policy </h1>
      <p>Last updated: 12/07/2020</p>
      <div className={classes.root}>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>1. Introduction</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Welcome to <b>Kinetx Co LLC</b>.
              </p>
              <p className="text-pad">
                Kinetx Co LLC (“us”, “we”, or “our”) operates
                https://www.getkit.us (hereinafter referred to as “
                <b>Service</b>”).
              </p>
              <p className="text-pad">
                Our Privacy Policy governs your visit to{" "}
                <a href="https://www.getkit.us">https://www.getkit.us</a>, and
                explains how we collect, safeguard and disclose information that
                results from your use of our Service.
              </p>
              <p className="text-pad">
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
              </p>
              <p className="text-pad">
                Our Terms and Conditions (“<b>Terms</b>”) govern all use of our
                Service and together with the Privacy Policy constitutes your
                agreement with us (“<b>agreement</b>
                ”).
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>2. Definitions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                <b>SERVICE</b> means the{" "}
                <a href="https://www.getkit.us">https://www.getkit.us</a>{" "}
                website operated by Kinetx Co LLC.
              </p>
              <p className="text-pad">
                <b>PERSONAL DATA</b> means data about a living individual who
                can be identified from those data (or from those and other
                information either in our possession or likely to come into our
                possession).
              </p>
              <p className="text-pad">
                <b>USAGE DATA</b> is data collected automatically either
                generated by the use of Service or from Service infrastructure
                itself (for example, the duration of a page visit).
              </p>
              <p className="text-pad">
                <b>COOKIES</b> are pieces of information saved by your web
                browser.
              </p>
              <p className="text-pad">
                <b>DATA CONTROLLER</b> means a natural or legal person who
                (either alone or jointly or in common with other persons)
                determines the purposes for which and the manner in which any
                personal data are, or are to be, processed. For the purpose of
                this Privacy Policy, we are a Data Controller of your data.
              </p>
              <p className="text-pad">
                <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural
                or legal person who processes the data on behalf of the Data
                Controller. We may use the services of various Service Providers
                in order to process your data more effectively.
              </p>
              <p className="text-pad">
                <b>DATA SUBJECT</b> is any living individual who is the subject
                of Personal Data.
              </p>
              <p className="text-pad">
                <b>THE USER</b> is the individual using our Service. The User
                corresponds to the Data Subject, who is the subject of Personal
                Data.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>
              3. Information Collection and Use
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className={classes.heading}>
              4. Types of Data Collected
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                <h3>Personal Data</h3>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (“<b>Personal Data</b>”). Personally
                identifiable information may include, but is not limited to:
                <ul>
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Date of birth</li>
                  <li>Cookies and Usage Data</li>
                </ul>
              </p>
              <p className="text-pad">
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link or by emailing at contact@kinetx.co.
              </p>
              <p className="text-pad">
                <h3>Usage Data</h3>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through a
                mobile device (“<b>Usage Data</b>”).
              </p>
              <p className="text-pad">
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p className="text-pad">
                When you access Service with a mobile device, this Usage Data
                may include information such as the type of mobile device you
                use, your mobile device unique ID, the IP address of your mobile
                device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic data.
              </p>
              <p className="text-pad">
                <h3>Location Data</h3>
                We may use and store information about your location if you give
                us permission to do so (“<b>Location Data</b>”). We use this
                data to provide features of our Service, to improve and
                customize our Service.
              </p>
              <p className="text-pad">
                You can enable or disable location services when you use our
                Service at any time by way of your device settings.
              </p>
              <p className="text-pad">
                <h3>Tracking Cookies Data</h3>
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </p>
              <p className="text-pad">
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
              </p>
              <p className="text-pad">
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </p>
              <p className="text-pad">
                Examples of Cookies we use:
                <ul>
                  <li>
                    <b>Session Cookies: </b>We use Session Cookies to operate
                    our Service.
                  </li>
                  <li>
                    <b>Preference Cookies: </b>We use Preference Cookies to
                    remember your preferences and various settings.
                  </li>
                  <li>
                    <b>Security Cookies: </b>We use Security Cookies for
                    security purposes.
                  </li>
                  <li>
                    <b>Advertising Cookies: </b>Advertising Cookies are used to
                    serve you with advertisements that may be relevant to you
                    and your interests.
                  </li>
                </ul>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography className={classes.heading}>5. Use of Data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Kinetx Co LLC uses the collected data for various purposes:
                <ul>
                  <li>to provide and maintain our Service;</li>
                  <li>to notify you about changes to our Service; </li>
                  <li>
                    to allow you to participate in interactive features of our
                    Service when you choose to do so;
                  </li>
                  <li>to provide customer support; </li>
                  <li>
                    to gather analysis or valuable information so that we can
                    improve our Service;
                  </li>
                  <li>to monitor the usage of our Service;</li>
                  <li>to detect, prevent and address technical issues;</li>
                  <li>
                    to fulfill any other purpose for which you provide it;
                  </li>
                  <li>
                    to carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection;
                  </li>
                  <li>
                    to provide you with notices about your account and/or
                    subscription, including expiration and renewal notices,
                    email-instructions, etc.;
                  </li>
                  <li>
                    to provide you with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless you have opted not to
                    receive such information;
                  </li>
                  <li>
                    in any other way we may describe when you provide the
                    information;
                  </li>
                  <li>for any other purpose with your consent. </li>
                </ul>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography className={classes.heading}>
              6. Retention of Data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p className="text-pad">
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography className={classes.heading}>
              7. Transfer of Data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Your information, including Personal Data, may be transferred to
                – and maintained on – computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </p>
              <p className="text-pad">
                If you are located outside United States and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to United States and process it there.
              </p>
              <p className="text-pad">
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>
              <p className="text-pad">
                Kinetx Co LLC will take all the steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this Privacy Policy and no transfer of your Personal Data will
                take place to an organization or a country unless there are
                adequate controls in place including the security of your data
                and other personal information.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography className={classes.heading}>
              8. Disclosure of Data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We may disclose personal information that we collect, or you
                provide:
                <ul>
                  <li>
                    <h4>Disclosure for Law Enforcement.</h4>Under certain
                    circumstances, we may be required to disclose your Personal
                    Data if required to do so by law or in response to valid
                    requests by public authorities.
                  </li>
                  <li>
                    <h4>Business Transaction.</h4>If we or our subsidiaries are
                    involved in a merger, acquisition or asset sale, your
                    Personal Data may be transferred.
                  </li>
                  <li>
                    <h4>Other cases. We may disclose your information also:</h4>
                    <ul className="nested-ul">
                      <li>to our subsidiaries and affiliates;</li>
                      <li>
                        to contractors, service providers, and other third
                        parties we use to support our business;
                      </li>
                      <li>to flifill the purpose for which you provide it;</li>
                      <li>
                        for the purpose of including your company’s logo on our
                        website;
                      </li>
                      <li>
                        for any other purpose disclosed by us when you provide
                        the information;
                      </li>
                      <li>with your consent in any other cases;</li>
                      <li>
                        if we believe disclosure is necessary or appropriate to
                        protect the rights, property, or safety of the Company,
                        our customers, or others.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
          >
            <Typography className={classes.heading}>
              9. Security of Data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
          >
            <Typography className={classes.heading}>
              10. Your Data Protection Rights Under General Data Protection
              Regulation (GDPR)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                If you are a resident of the European Union (EU) and European
                Economic Area (EEA), you have certain data protection rights,
                covered by GDPR. – See more at
                https://eur-lex.europa.eu/eli/reg/2016/679/oj
              </p>
              <p className="text-pad">
                We aim to take reasonable steps to allow you to correct, amend,
                delete, or limit the use of your Personal Data.
              </p>
              <p className="text-pad">
                If you wish to be informed what Personal Data we hold about you
                and if you want it to be removed from our systems, please email
                us at contact@kinetx.co.
              </p>
              <p className="text-pad">
                In certain circumstances, you have the following data protection
                rights:
                <ul>
                  <li>
                    the right to access, update or to delete the information we
                    have on you;
                  </li>
                  <li>
                    the right of rectification. You have the right to have your
                    information rectified if that information is inaccurate or
                    incomplete;
                  </li>
                  <li>
                    the right to object. You have the right to object to our
                    processing of your Personal Data;
                  </li>
                  <li>
                    the right of restriction. You have the right to request that
                    we restrict the processing of your personal information;
                  </li>
                  <li>
                    the right to data portability. You have the right to be
                    provided with a copy of your Personal Data in a structured,
                    machine-readable and commonly used format;
                  </li>
                  <li>
                    the right to withdraw consent. You also have the right to
                    withdraw your consent at any time where we rely on your
                    consent to process your personal information;
                  </li>
                </ul>
              </p>
              <p className="text-pad">
                Please note that we may ask you to verify your identity before
                responding to such requests. Please note, we may not able to
                provide Service without some necessary data.
              </p>
              <p className="text-pad">
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11a-content"
            id="panel11a-header"
          >
            <Typography className={classes.heading}>
              11. Your Data Protection Rights under the California Privacy
              Protection Act (CalOPPA)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law’s reach stretches well beyond California to
                require a person or company in the United States (and
                conceivable the world) that operates websites collecting
                personally identifiable information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals with whom
                it is being shared, and to comply with this policy. – See more
                at:
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
              </p>
              <p className="text-pad">
                According to CalOPPA we agree to the following:
                <ul>
                  <li>users can visit our site anonymously;</li>
                  <li>
                    our Privacy Policy link includes the word “Privacy”, and can
                    easily be found on the page specified above on the home page
                    of our website;
                  </li>
                  <li>
                    users will be notified of any privacy policy changes on our
                    Privacy Policy Page;
                  </li>
                  <li>
                    users are able to change their personal information by
                    emailing us at contact@kinetx.co.
                  </li>
                </ul>
              </p>
              <p className="text-pad">
                <span>Our Policy on “Do Not Track” Signals:</span>
                Your browser settings may allow you to transmit “Do Not Track”
                signals to websites and online services that you visit. Websites
                or online services may or may not honor such requests. We do not
                alter our practices when we receive a “Do Not Track” signal from
                a user’s browser.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12a-content"
            id="panel12a-header"
          >
            <Typography className={classes.heading}>
              12. Your Data Protection Rights under the California Consumer
              Privacy Act (CCPA)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                If you are a California resident, you are entitled to learn what
                data we collect about you, ask to delete your data and not to
                sell (share) it. To exercise your data protection rights, you
                can make certain requests and ask us:
              </p>
              <p className="text-pad">
                <ul>
                  <li>
                    <b>What personal information we have about you.</b> If you
                    make this request, we will return to you:
                    <ul>
                      <li>
                        The categories of personal information we have collected
                        about you.
                      </li>
                      <li>
                        The categories of sources from which we collect your
                        personal information.
                      </li>
                      <li>
                        The business or commercial purpose for collecting or
                        selling your personal information.
                      </li>
                      <li>
                        The categories of third parties with whom we share
                        personal information.
                      </li>
                      <li>
                        The specific pieces of personal information we have
                        collected about you.
                      </li>
                      <li>
                        A list of categories of personal information that we
                        have sold, along with the category of any other company
                        we sold it to. If we have not sold your personal
                        information, we will inform you of that fact.
                      </li>
                      <li>
                        A list of categories of personal information that we
                        have disclosed for a business purpose, along with the
                        category of any other company we shared it with.
                      </li>
                    </ul>
                    <p>
                      Please note, you are entitled to ask us to provide you
                      with this information up to two times in a rolling
                      twelve-month period. When you make this request, the
                      information provided may be limited to the personal
                      information we collected about you in the previous 12
                      months.
                    </p>
                  </li>
                  <li>
                    <b>To delete your personal information.</b> If you make this
                    request, we will delete the personal information we hold
                    about you as of the date of your request from our records
                    and direct any service providers to do the same. In some
                    cases, deletion may be accomplished through
                    de-identification of the information. If you choose to
                    delete your personal information, you may not be able to use
                    certain functions that require your personal information to
                    operate.
                  </li>
                  <li>
                    <p>
                      <b>To stop selling your personal information.</b> We do
                      not sell your personal information for monetary
                      consideration. However, under some circumstances, a
                      transfer of personal information to a third party, or
                      within our family of companies, without monetary
                      consideration may be considered a “sale” under California
                      law.
                    </p>
                    <p>
                      If you submit a request to stop selling your personal
                      information, we will stop making such transfers. If you
                      are a California resident, to opt-out of the sale of your
                      personal information, click “Do Not Sell My Personal
                      Information” at the bottom of our home page to submit your
                      request.
                    </p>
                    <p>
                      Please note, if you ask us to delete or stop selling your
                      data, it may impact your experience with us, and you may
                      not be able to participate in certain programs or
                      membership services which require the usage of your
                      personal information to function. But in no circumstances,
                      we will discriminate against you for exercising your
                      rights.
                    </p>
                    <p>
                      To exercise your California data protection rights
                      described above, please send your request(s) by one of the
                      following means:
                      <ul>
                        <li className="list-none">
                          By email: contact@kinetx.co
                        </li>
                        <li className="list-none">
                          By visiting this page on our website:
                          <a href="https://www.kinetx.co/contact-us">
                            https://www.kinetx.co/contact-us
                          </a>
                          .
                        </li>
                      </ul>
                    </p>
                    <p>
                      Your data protection rights, described above, are covered
                      by the CCPA, short for the California Consumer Privacy
                      Act. To find out more, visit the official California
                      Legislative Information website. The CCPA took effect on
                      01/01/2020.
                    </p>
                  </li>
                </ul>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13a-content"
            id="panel13a-header"
          >
            <Typography className={classes.heading}>
              13. Service Providers
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We may employ third party companies and individuals to
                facilitate our Service (“<b>Service Providers</b>”), provide
                Service on our behalf, perform Service-related tasks, or assist
                us in analyzing how our Service is used.
              </p>
              <p className="text-pad">
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf. The tasks performed by these
                third parties include but are not limited to: analytics,
                advertising, behavioral remarketing, and payments.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14a-content"
            id="panel14a-header"
          >
            <Typography className={classes.heading}>
              14. Links to Other Sites
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>
              <p className="text-pad">
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15a-content"
            id="panel15a-header"
          >
            <Typography className={classes.heading}>
              15. Children's Privacy
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Our Services are not intended for use by children under the age
                of 16 (“<b>Children</b>”).
              </p>
              <p className="text-pad">
                We do not knowingly collect personally identifiable information
                from Children under 16. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel16a-content"
            id="panel16a-header"
          >
            <Typography className={classes.heading}>
              16. Changes to This Privacy Policy
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p className="text-pad">
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                “effective date” at the top of this Privacy Policy.
              </p>
              <p className="text-pad">
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel17a-content"
            id="panel17a-header"
          >
            <Typography className={classes.heading}>17. Contact Us</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                If you have any questions about this Privacy Policy, please
                contact us:
              </p>
              <p className="text-pad">By email: contact@kinetx.co.</p>
              <p className="text-pad">
                By visiting this page on our website:
                <a href="https://www.kinetx.co/contact-us">
                  https://www.kinetx.co/contact-us
                </a>
                .
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Privacy;
