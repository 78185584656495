import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import './Modals.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  h2: {
    fontWeight: 400,
    fontSize: '2.5rem',
    padding: '1rem',
  },
  paper: {
    color: 'var(--mainColor)',
    backgroundColor: '#ffffff',
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: 'var(--mainColor)',
    color: '#ffffff',
    padding: '0.5rem 1rem',
    alignSelf: 'center',
    marginBottom: '2rem',
    '&:hover': {
      backgroundColor: 'var(--newColor)',
    },
  },
  img: {
    width: '25%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid red',
  },
}));

const themeInstance = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
};

export const NextModal = () => {
  return (
    <div className='next_contact flex column_center'>
      <h2>Take your business' communication to the next level</h2>
      {/* <p>
    It's time to take your telephone services to the next level. All
    these features and integrations are{' '}
    <span>included in your plan</span> so what are you waiting for?
  </p> */}
      <Button
        href='./contact'
        style={{
          backgroundColor: 'tomato',
          width: 'auto',
          padding: '0.5rem 1rem',
          color: '#f8f8f8',
          marginTop: '2rem',
        }}
      >
        Contact Us
      </Button>
    </div>
  );
};

export const IntegrationsModal = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isShown, setIsShown] = React.useState(false);

  const toggleShown = () => {
    setIsShown(!isShown);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className='integrations_card'>
        <div className='inner_integrations'>
          <h2>Add additional integrations to improve workload management.</h2>
          <div className='integrations_imgs'>
            {/* <div> */}
            <img src={require('../../Images/Gsuite.png')} alt='G Suite' />
            <img src={require('../../Images/slack.jpg')} alt='Slack' />
            <img src={require('../../Images/office365.jpg')} alt='Office 365' />
            {/* </div> */}
            <div className={isShown ? ' imgs_show' : 'imgs_hide'}>
              <img
                src={require('../../Images/salesforce.jpg')}
                alt='Salesforce'
              />
              <img src={require('../../Images/zoho.jpg')} alt='Zoho' />
              <img src={require('../../Images/clio.jpg')} alt='Clio' />
            </div>
          </div>
        </div>
        <Button className={classes.button} onClick={toggleShown}>
          {isShown ? 'Hide Integrations' : 'See All Integrations'}
        </Button>
        <p>
          Want to integrate with KIT?{' '}
          <Link to='/contact' className='modal_contact'>
            {' '}
            Contact us
          </Link>
        </p>
      </div>
      <div className='integrations_modal'>
        <Modal
          aria-labelledby='integrations-modal'
          aria-describedby='integrations-modal-description'
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          theme={themeInstance}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h1 className={classes.h2}>KIT integrations</h1>
              <div className='integrations_imgs_modal'>
                <img
                  src={require('../../Images/Gsuite.png')}
                  alt='G Suite'
                  className={classes.img}
                />
                <img
                  src={require('../../Images/slack.jpg')}
                  alt='Slack'
                  className={classes.img}
                />
                <img
                  src={require('../../Images/office365.jpg')}
                  alt='Office 365'
                  className={classes.img}
                />
                <img
                  src={require('../../Images/salesforce.jpg')}
                  alt='Salesforce'
                  className={classes.img}
                />
                <img
                  src={require('../../Images/zoho.jpg')}
                  alt='Zoho'
                  className={classes.img}
                />
                <img
                  src={require('../../Images/clio.jpg')}
                  alt='Clio'
                  className={classes.img}
                />
              </div>
              <p>
                Want to integrate with KIT?{' '}
                <Link to='/contact' className='modal_contact'>
                  {' '}
                  Contact us
                </Link>
              </p>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};
