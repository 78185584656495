import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ThemeProvider } from "@material-ui/styles";

import "./Steps.css";

const GreenCheckbox = withStyles({
  root: {
    // flexBasis: '30%',
    color: "var(--secColor)",
    "&$checked": {
      color: "var(--mainColor)",
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  formControl: {
    flexBasis: "30%",
  },
}));

const StepThree = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
    checkedD: true,
    checkedE: true,
    checkedF: true,
    checkedG: true,
    checkedH: true,
    checkedI: true,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Fragment>
      <div className="step step_three flex column_center">
        <h2>
          Select the features you would like to add to your
          KIT&nbsp;telephone&nbsp;system
        </h2>
        <div className="step_checkes flex row_center">
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
              />
            }
            label="Call Recording"
          />
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedB}
                onChange={handleChange}
                name="checkedB"
              />
            }
            label="Auto Attendant"
          />
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedC}
                onChange={handleChange}
                name="checkedC"
              />
            }
            label="HD Voice"
          />
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedD}
                onChange={handleChange}
                name="checkedD"
              />
            }
            label="Call Forwarding"
          />
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedE}
                onChange={handleChange}
                name="checkedE"
              />
            }
            label="Video Conferencing"
          />
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedF}
                onChange={handleChange}
                name="checkedF"
              />
            }
            label="Call Queue"
          />
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedG}
                onChange={handleChange}
                name="checkedG"
              />
            }
            label="Number Porting"
          />
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedH}
                onChange={handleChange}
                name="checkedH"
              />
            }
            label="Call Routing"
          />
          <FormControlLabel
            // className={classes.formControl}
            className="step_three_item"
            control={
              <GreenCheckbox
                checked={state.checkedI}
                onChange={handleChange}
                name="checkedI"
              />
            }
            label="Call Analytics"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default StepThree;
