import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import "./Landing.css";

const Landing = () => {
  return (
    <Fragment>
      <div className="landing">
        <div className="overlay">
          <div className="welcome">
            <h1>
              {/* Make the smart decision around your business’s phone communication
              system */}
              Stop paying for unused room lines.
            </h1>
            <p className="welcome-text">
              {/* Learn the difference between hosted PBX solutions and other
              communications solutions and determine the best approach for your
              business. */}
              Learn the difference between traditional VoIP systems and a
              dedicated cloud PBX system customized for and owned by your hotel.
            </p>
            <Button
              variant="contained"
              style={{
                color: "#ffffff",
                backgroundColor: "tomato",
                textAlign: "center",
                boxShadow: "none",
              }}
              href="./splash"
            >
              {/* DOWNLOAD YOUR FREE FACT SHEET */}
              Download your free information&nbsp;sheet
            </Button>
            {/* <Button
            variant='contained'
            style={{
              color: '#ffffff',
              backgroundColor: 'tomato',
            }}
            href='./form'
          >
            mail
          </Button> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Landing;
