import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Button } from '@material-ui/core';
import { IntegrationsModal, NextModal } from '../Modals/Modals';

import './Legal.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  h2: {
    fontWeight: 400,
    fontSize: '2.5rem',
    padding: '1rem',
  },
  paper: {
    color: 'var(--mainColor)',
    backgroundColor: '#ffffff',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: 'var(--mainColor)',
    color: '#ffffff',
    padding: '0.5rem 1rem',
    '&:hover': {
      backgroundColor: 'var(--newColor)',
    },
  },
  img: {
    width: '25%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid red',
  },
}));

const themeInstance = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
};

const Legal = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment className='legal'>
      <div className='legal_hero'>
        <div className='blue_overlay'>
          <h1>Legal Communication Solutions</h1>
          {/* <p>
            Let KIT make the case for efficiency, productivity and reliability
          </p> */}
        </div>
      </div>
      <div className='legal_main_top'>
        <div className='legal_main_card shadow-bottom-left'>
          <div className='legal_main_card_item'>
            <h2>Communicating with clients just got easier</h2>
            <p>
              Whether you are a sole practitioner or large law firm, your
              business needs a reliable and efficient telephone solution. KIT
              offers you unmatched features, third-party integrations, and
              solutions all included in your monthly plan.
            </p>
          </div>
          <div className='legal_main_card_item main_card_img'></div>
        </div>
        <div className='legal_main_middle'>
          <h1>Features In Your KIT Toolbelt</h1>
          <div className='legal_main_imgs'>
            <img
              src={require('../../Images/extraLines.png')}
              alt='Extra Lines & Stations At No Extra Cost'
            />
            <img
              src={require('../../Images/callrec.png')}
              alt='Automatic Call Recording'
            />
            <img
              src={require('../../Images/voicemail.png')}
              alt='Voicemail to Email Notifications'
            />
          </div>
          <Button href='./features' className={classes.button}>
            See All Features
          </Button>
        </div>
        <div className='legal_integrations'>
          <div className='divider'></div>
          <h1>
            Clio, Clio & <span>Clio!</span>
          </h1>
          <div className='legal_integrations_card shadow'>
            <div className='clio_img'>
              <img src={require('../../Images/clio.jpg')} alt='Clio' />
            </div>
            <p className='clio_text shadow_bottom_right'>
              Take advantage of KIT's full potential when you integrate it with
              your case management system. Track billable hours, manage your
              clients, cases, and appointments in a new way. Your Clio law firm
              solutions just got even faster and more efficient so you and your
              associates can focus on your cases.
            </p>
          </div>
          <div className='legal_more_integrations'>
            <IntegrationsModal />

            <div className='divider'></div>
            <div className='legal_recommendations'>
              <div className='legal_recommendations_card'>
                <div className='atash_img'></div>
                <div className='atash_text'>
                  <p>
                    "We’ve been searching for the right solution for our law
                    firm, then we found KIT. It's the best scalable solution for
                    our office and save us costs."
                  </p>
                  <p>- Shlomi Atash, Immigration Lawyer, Atash Law</p>
                </div>
              </div>
            </div>
            <div className='divider'></div>
          </div>
          <div className='legal_bottom'>
            <h1>Why You'll Love Your KIT</h1>
            <div className='legal_why shadow'>
              <div className='legal_why_text shadow-bottom-left'>
                <p>
                  KIT cuts down communication costs while providing the latest
                  technology helping your business grow.
                </p>
                <div className='legal_why_checkes flex'>
                  <div className='legal_check_box flex row_center pad12'>
                    <div className='legal_check'>
                      <CheckCircleIcon />
                    </div>
                    <span>Easy To Use</span>
                  </div>
                  <div className='legal_check_box flex row_center pad12'>
                    <div className='legal_check'>
                      <CheckCircleIcon />
                    </div>
                    <span>Fully Customizable</span>
                  </div>
                  <div className='legal_check_box flex row_center pad12'>
                    <div className='legal_check'>
                      <CheckCircleIcon />
                    </div>
                    <span>No Contracts</span>
                  </div>
                  <div className='legal_check_box flex row_center pad12'>
                    <div className='legal_check'>
                      <CheckCircleIcon />
                    </div>
                    <span>Third-Party App Integration</span>
                  </div>
                </div>
              </div>
              <div className='legal_why_img shadow_bottom_right'></div>
            </div>
            <div className='divider'></div>

            <NextModal />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Legal;
